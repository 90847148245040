import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';

/**
 * This mutation triggers an AI analyse of the KoLab report and populate the KoLab's summary fields with the AI <output className=""></output>
 * It re-fetches the KoLab on success to display these new values
 */
export const useAIAnalyzeKolab = () => {
  const queryClient = useQueryClient();
  const { addToast } = CustomToast();

  return useMutation({
    mutationFn: async (engagementId: string) => {
      if (!engagementId) {
        throw new Error('No engagementId');
      }

      await apiClient.adBoardEngagementsApi.adBoardEngagementControllerAnalyseResults(
        engagementId
      );
    },
    onError: (error: unknown) => {
      const message = (error as Error)?.message || 'An error occurred';
      addToast(message, 'warning', 'Warning');
    },
    // The onSuccess callback receives the mutation result as the first argument and the input variable (engagementId) as the second argument.
    onSuccess: (_result, engagementId) => {
      // Invalidate query to refetch updated engagement
      queryClient.invalidateQueries({
        queryKey: ['fetchEngagementById', engagementId],
      });

      addToast('Analysis completed successfully!', 'success', 'Success');
    },
  });
};
